@charset "UTF-8";
@import "variables";
@import "mixin";

/* modules　
================================================ */

@include sp { 
	.pc { 
		display: none!important;
	}
}
@include pc { 
	.sp { 
		display: none!important;
	}
}


/* Parts
================================================ */

.dbl { 
	vertical-align: top;
	line-height: 3.94;
	@include pc { 
		line-height: 1;
	}
}
#homeIntro,.mod_list01 { 
	padding: 24px 15px 20px;
	@include pc { 
		padding: 33px 0 20px;
	}
	h2 { 
		
	}
	.list { 
		> li { 
			margin-bottom: 9px;
			@include pc { 
				float: left;
				width: 273px;
				margin: 0 0 22px 20px;
				&:nth-child(3n+1) { 
					margin-left: 0;
				}
			}
			a { 
				position: relative;
				overflow: hidden;
				&:hover { 
					.img { 
						img { 
							
						}
					}
				}
			}
			.img { 
				@include pc { 
					z-index: 1;
					position: relative;
					overflow: hidden;
					@include transition;
				}
				&:hover { 
					@include pc { 
						@include hover;
					}
				}
			}
			.box { 
				background: #fff;
				padding: 15px 15px 10px;
				@include pc { 
					z-index: 2;
					position: relative;
					padding: 16px 20px 10px;
				}
				.tag { 
					display: inline-block;
					font-size: 1.3rem;
					color: #fff;
					font-weight: 500;
					letter-spacing: .1rem;
					padding: 1px 9px;
					border-radius: 3px;
					-webkit-border-radius: 3px;
					-moz-border-radius: 3px;
					@include pc { 
						font-size: 1.4rem;
						padding: 4px 12px;
						line-height: 1.05;
						margin-bottom: 3px;
					}
					&01 { 
						background: #f36259;
					}
					&02 { 
						background: #2eabc2;
					}
					&03 { 
						background: #2eabc2;
					}
					&04 { 
						background: #fc7e40;
					}
					&05 { 
						background: #80d139;
					}
				}
				h3 { 
					font-size: 1.6rem;
					border-bottom: 2px solid #edeff1;
					padding: 7px 15px 10px;
					margin: 0 -15px;
					letter-spacing: .1rem;
					line-height: 1.35;
					@include pc { 
						font-size: 1.7rem;
						border-bottom: 2px solid #edeff1;
						padding: 7px 20px 20px;
						margin: 0 -20px;
						letter-spacing: .1rem;
						line-height: 1.45;
					}
				}
				.btm { 
					font-size: 1.2rem;
					text-align: right;
					letter-spacing: .1rem;
					padding: 10px 0 0px;
					@include pc { 
						font-size: 1.3rem;
						text-align: right;
						letter-spacing: .1rem;
						padding: 14px 0 5px;
					}
				}
				.date { 
					display: inline-block;
					color: #aaaaaa;
					padding-right: 10px;
				}
				.place { 
					display: inline-block;
					span { 
						display: inline-block;
						background: url(../img/top/ico02.png) no-repeat left top;
						background-size: 9px;
						padding-left: 12px;
						@include pc { 
							background: url(../img/top/ico02.png) no-repeat left 5px;
							background-size: 9px;
							padding-left: 14px;
							letter-spacing: .05rem;
						}
					}
				}
			}
			&:first-child { 
				a { 
					&::after { 
						content: "";
						position: absolute;
						z-index: 3;
						left: 0;
						top: 0;
						width: 45px;
						height: 45px;
						background: url(../img/top/ico_new.png) no-repeat center top;
						background-size: contain;
						@include pc { 
							width: 50px;
							height: 50px;
						}
					}
				}
			}
			&:nth-child(-n+3) { 
				.date { 
					
				}
			}
			&:nth-child(n+4) { 
				@include sp { 
					padding: 13px 0 13px;
					border-bottom: 1px solid #dcdcdc;
					margin-bottom: 2px;
					.img { 
						float: left;
						width: 31%;
					}
					.box { 
						float: right;
						width: 65.5%;
						background: transparent;
						padding: 0;
						.tag { 
							font-size: 1rem;
							padding: 2px 5px;
						}
						h3 { 
							font-size: 1.3rem;
							border-bottom: none;
							padding: 5px 0 0px;
							margin: 0;
						}
						.btm { 
							font-size: 1rem;
						}
						.date { 
							
						}
						.place { 
							span { 
								background: url(../img/top/ico02.png) no-repeat left top;
								background-size: 8px;
							}
						}
					}
				}
			}
		}
	}
}

/* move line
================================================ */



/* Title
================================================ */

.mod_tit01 { 
	font-size: 2rem;
	border-bottom: 2px solid $colorNavy;
	padding: 0 0 5px 30px;
	margin-bottom: 15px;
	background: url(../img/top/ico01.png) no-repeat left 5px;
	background-size: 19px;
	@include pc { 
		font-size: 2.4rem;
		padding: 0 0 4px 34px;
		margin-bottom: 26px;
		background: url(../img/top/ico01.png) no-repeat left 9px;
		background-size: 25px;
	}
}

.mod_tit02 { 
	line-height: 1.411764705882353;
	font-size: 1.7rem;
	border-bottom: 2px solid #0c2f44;
	letter-spacing: .1rem;
	padding-bottom: 11px;
	margin-bottom: 20px;
	@include pc { 
		font-size: 2.4rem;
		padding: 20px 0 15px;
		margin-bottom: 30px;
	}
}


/* Btn
================================================ */

.goTop { 
	text-align: center;
	a { 
		background: #e7e4dc;
		padding: 11px 0;
		position: relative;
		text-align: center;
		font-size: 1.2rem;
		color: $colorNavy;
		font-weight: 600;
		letter-spacing: .1rem;
		span { 
			display: inline-block;
			position: relative;
			&::after  { 
				position: absolute;
				content: "";
				left: -20px;
				top: 3px;
				width: 12px;
				height: 12px;
				background: url(../img/common/footer/ico_pagetop.png) no-repeat center center;
				background-size: contain;
			}
		}
	}
}

/* Table
================================================ */

.mod_table01 { 
	border-top: 1px solid #c2c2c2;
	font-size: 1.2rem;
	letter-spacing: .07rem;
	margin-bottom: 20px;
	tr { 
		th { 
			border-bottom: 1px solid #c2c2c2;
			background: #f4f3ed;
			text-align: center;
			vertical-align: top;
			padding: 15px 0 11px;
			width: 32%;
		}
		td { 
			border-bottom: 1px solid #c2c2c2;
			text-align: left;
			vertical-align: top;
			padding: 9px 15px;
			background: #fff;
			line-height: 1.92;
			a { 
				color: $siteColor;
				span { 
					display: inline-block;
					padding-right: 13px;
					background: url(../img/way/ico05.png) no-repeat right center;
					background-size: 10px;
				}
			}
		}
	}
	@include pc { 
		border-top: 1px solid #c2c2c2;
		font-size: 1.5rem;
		letter-spacing: .07rem;
		margin-bottom: 30px;
		tr { 
			th { 
				border-bottom: 1px solid #c2c2c2;
				background: #f4f3ed;
				text-align: center;
				vertical-align: top;
				padding: 15px 0 11px;
				width: 19.5%;
			}
			td { 
				border-bottom: 1px solid #c2c2c2;
				text-align: left;
				vertical-align: top;
				padding: 12px 20px;
				background: #fff;
				line-height: 1.92;
				a { 
					color: $siteColor;
					&:hover { 
						@include opacity;
					}
					span { 
						display: inline-block;
						padding-right: 28px;
						background: url(../img/way/ico05.png) no-repeat right 10px;
						background-size: 16px;
					}
				}
			}
		}
	}
}




/* Cv
================================================ */


/* arrow
================================================ */

.mod_arw01 { 
	display: block;
	width: 10px;
	height: 10px;
	border-top: 2px solid $siteColor;
	border-right: 2px solid $siteColor;
	transform: rotate(45deg);
}


/* pageNav
================================================ */

#pageNav01 { 
	padding: 5px 26px 25px;
	position: relative;
	font-size: 1.3rem;
	font-weight: 600;
	text-align: center;
	li { 
		text-align: center;
		color: #3880c4;
		display: inline-block;
		margin: 0;
		a,span { 
			color: #fff;
			background: $colorNavy;
			display: block;
			padding: 4px 5px 0;
			min-height: 26px;
			min-width: 26px;
			&:hover { 
				background: transparent;
				color: $txtcolor;
			}
		}
		&:nth-child(2) { 
			a { 
				padding: 0;
				position: relative;
				&::after { 
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					margin: 0 auto;
					top: 7px;
					display: block;
					width: 8px;
					height: 12px;
					background: url(../img/category/ico07.png) no-repeat center center;
					background-size: contain;
					@include transition;
				}
				&:hover { 
					background: $colorNavy;
					color: #fff;
				}
			}
		}
		&:nth-last-child(2) { 
			a { 
				padding: 0;
				position: relative;
				@include transition;
				&::after { 
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					margin: 0 auto;
					top: 7px;
					display: block;
					width: 8px;
					height: 12px;
					background: url(../img/category/ico08.png) no-repeat center center;
					background-size: contain;
				}
				&:hover { 
					background: $colorNavy;
					color: #fff;
				}
			}
		}
		&.current span { 
			background: transparent;
			color: $txtcolor;
		}
		&.previous { 
			position: absolute;
			left: 0;
			top: 5px;
			margin: 0;
			a { 
				position: relative;
				&::after { 
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					margin: 0 auto;
					top: 7px;
					display: block;
					width: 13px;
					height: 12px;
					background: url(../img/category/ico06.png) no-repeat center center;
					background-size: contain;
					@include transition;
				}
				&:hover { 
					background: $colorNavy;
					color: #fff;
				}
			}
		}
		&.next { 
			position: absolute;
			right: 0;
			top: 5px;
			margin: 0;
			a { 
				position: relative;
				&::after { 
					content: "";
					position: absolute;
					right: 0;
					left: 0;
					margin: 0 auto;
					top: 7px;
					display: block;
					width: 13px;
					height: 12px;
					background: url(../img/category/ico09.png) no-repeat center center;
					background-size: contain;
					@include transition;
				}
				&:hover { 
					background: $colorNavy;
					color: #fff;
				}
			}
		}
	}
	@include pc { 
		width: 355px;
		margin: 0 auto;
		padding: 5px 26px 35px;
		position: relative;
		font-size: 1.5rem;
		font-weight: 600;
		text-align: center;
		li { 
			text-align: center;
			color: #3880c4;
			display: inline-block;
			margin: 0;
			a,span { 
				color: #fff;
				background: $colorNavy;
				display: block;
				padding: 1px 5px 0;
				min-height: 30px;
				min-width: 30px;
				&:hover { 
					background: transparent;
					color: $txtcolor;
				}
			}
			&:nth-child(2) { 
				a { 
					padding: 0;
					position: relative;
					&::after { 
						content: "";
						position: absolute;
						left: 0;
						right: 0;
						margin: 0 auto;
						top: 9px;
						display: block;
						width: 9px;
						height: 13px;
						background: url(../img/category/ico07.png) no-repeat center center;
						background-size: contain;
						@include transition;
					}
					&:hover { 
						background: $colorNavy;
						color: #fff;
						@include opacity;
					}
				}
			}
			&:nth-last-child(2) { 
				a { 
					padding: 0;
					position: relative;
					@include transition;
					&::after { 
						content: "";
						position: absolute;
						left: 0;
						right: 0;
						margin: 0 auto;
						top: 9px;
						display: block;
						width: 9px;
						height: 13px;
						background: url(../img/category/ico08.png) no-repeat center center;
						background-size: contain;
					}
					&:hover { 
						background: $colorNavy;
						color: #fff;
						@include opacity;
					}
				}
			}
			&.current span { 
				background: transparent;
				color: $txtcolor;
			}
			&.previous { 
				position: absolute;
				left: 0;
				top: 5px;
				margin: 0;
				a { 
					position: relative;
					&::after { 
						content: "";
						position: absolute;
						left: 0;
						right: 0;
						margin: 0 auto;
						top: 9px;
						display: block;
						width: 14px;
						height: 13px;
						background: url(../img/category/ico06.png) no-repeat center center;
						background-size: contain;
						@include transition;
					}
					&:hover { 
						background: $colorNavy;
						color: #fff;
						@include opacity;
					}
				}
			}
			&.next { 
				position: absolute;
				right: 0;
				top: 5px;
				margin: 0;
				a { 
					position: relative;
					&::after { 
						content: "";
						position: absolute;
						right: 0;
						left: 0;
						margin: 0 auto;
						top: 9px;
						display: block;
						width: 14px;
						height: 13px;
						background: url(../img/category/ico09.png) no-repeat center center;
						background-size: contain;
						@include transition;
					}
					&:hover { 
						background: $colorNavy;
						color: #fff;
						@include opacity;
					}
				}
			}
		}
	}
}



