@charset "UTF-8";

$txtcolor: #333;
$acolor: #333;
$hoverColor: #f36259;
$bgcolor: #f4f3ed;
$siteColor: #f36259;
$colorNavy: #193040;

$roboto: 'Roboto', sans-serif;
$yuMincho: 'YuMincho','Yu Mincho',"Hiragino Mincho ProN","Hiragino Mincho Pro","ＭＳ 明朝", sans-serif;
$yuGothic: "游ゴシック","Yu Gothic","游ゴシック体","YuGothic","Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN","Hiragino Kaku Gothic Pro","ヒラギノ角ゴ Pro W3","メイリオ","Meiryo",Verdana,Arial,Helvetica,sans-serif;

$txt_1_1: 1.1rem;
$txt_1_2: 1.2rem;
$txt_1_3: 1.3rem;
$txt_1_4: 1.4rem;
$txt_1_5: 1.5rem;
$txt_1_6: 1.6rem;
$txt_1_7: 1.7rem;

$font_400: 400;
$font_500: 500;
$font_600: 600;
$font_700: 700;
$font_800: 800;
$font_900: 900;

$lineHeight: 1.92;
$shadow: 0px 2px 0px rgba(0, 0, 0, 0.3);

$width: 1200px;
