@charset "UTF-8";

@mixin sp { 
	@media screen and (max-width: 599px) { 
		@content;
	}
}

@mixin pc { 
	@media screen and (min-width: 600px) { 
		@content;
	}
}

@mixin bg { 
	
}

@mixin transition { 
	-webkit-transition: all ease-out 0.3s;
	-moz-transition: all ease-out 0.3s;
	-ms-transition: all ease-out 0.3s;
	transition: all ease-out 0.3s;
}

@mixin hover { 
  transform: scale(1.035);
  -ms-transform: scale(1.035);
  -webkit-transform: scale(1.035);
  -o-transform: scale(1.035);
  -moz-transform: scale(1.035);
}

@mixin opacity{ 
	opacity: 0.7;
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
}

@mixin btn { 
	
}
